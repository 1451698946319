import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Button from "../components/button"
import headerImage from "../images/header.png"
import MockupContent from "./image"
import { COLORS } from "../styles/constants"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          subtitle
          description
        }
      }
    }
  `)

  const { title, subtitle, description } = data.site.siteMetadata

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "4rem 1rem",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${headerImage})`,
          position: "absolute",
          top: 0,
          zIndex: -5,
          height: "95vh",
          width: "95vw",
          opacity: 0.5,
        }}
      />
      <h1 style={{ textAlign: "center", color: COLORS.lightWhite }}>{title}</h1>
      <h3 style={{ textAlign: "center", color: COLORS.lightWhite }}>
        {subtitle}
      </h3>
      <p
        style={{ textAlign: "center", maxWidth: 440, color: COLORS.lightGray }}
      >
        {description}
      </p>
      <Button component="a" href="https://raidr.app">
        Try Here
      </Button>
      <div
        style={{
          margin: 60,
          width: `250px`,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <MockupContent />
      </div>
    </div>
  )
}

export default Header
