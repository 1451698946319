import React from "react"

import { COLORS, BORDER_RADIUS, GRADIENT } from "../styles/constants"
import "../styles/button.css"

const Button = ({ children, component: Component, ...rest }) => (
  <Component
    {...rest}
    style={{
      padding: ".5rem 2.5rem",
      color: COLORS.lightWhite,
      fontWeight: 700,
      background: GRADIENT,
      borderRadius: BORDER_RADIUS,
      borderWidth: 0,
      cursor: "pointer",
      textDecoration: "none",
    }}
  >
    {children}
  </Component>
)

Button.defaultProps = {
  component: "button",
}

export default Button
